.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buttons {
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  
  h1 {
    font-size: 28px;
    margin-top: 20px;
    font-weight: normal;
    margin-bottom: 0;

    @media(min-width: 600px) {
      margin-right: 10px;
    }
  }

  h3 {
    margin: 10px 0;
  }

  .sectionHeader {
    font-size: 20px;
  }


  .width {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 10px;
    bottom: 10px;
    font-weight: bolder;
    font-size: 16px;
  }
}

.underlined {
  border-bottom: solid 1px #8d8d8d;
}

.bold {
  font-weight: bold;
}

.iconButton {
  margin: 0 0 0 10px;
}

.saveButton {
  color: green;
}

.cancelButton {
  color: red;
}
