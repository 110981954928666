.container {
  @media (min-width: 800px) {
    display: grid;
    grid-template-columns: 200px 1fr;
  }

  .children {
    padding: 0 20px;
    
    @media (min-width: 800px) {
      padding: 0 60px;
      grid-column: 2;
    }
  }
}