.section {
  margin: 30px 10px;
}

.content {
  margin: 30px 0;
}

.underlined {
  border-bottom: 1px solid #4d4d4d;

}

.sunday {
  margin: 30px 0;
}

.hour {
  margin: 15px 0;

  .time {
    color: green;
    margin-left: 10px;
  }
}