.title {
  text-align: center;
  margin-bottom: 10px;
  font-weight: normal;

  @media (min-width: 800px) {
    text-align: start;
    margin-left: 10px;
  }
}

.wardName {
  font-weight: bold;
}

.subtitle {
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: normal;

  @media (min-width: 800px) {
    text-align: start;
    margin-left: 10px;
  }
}

.images {
  display: grid;

  @media (min-width: 700px) {
    display: flex;
    align-items: center;
  }

  @media (min-width: 920px) {
    align-items: center;
    justify-content: flex-start;
  }

  .image {
    width: 90%;
    min-width: 150px;
    max-width: 600px;
    margin: 10px;
    border: solid 1px #8d8d8d;
    border-radius: 6px;
    box-shadow: 5px 5px 5px #8d8d8d;
  }
}

.images .christ {
  margin: 20px auto;
  max-width: 400px;

  @media (min-width: 700px) {
    max-width: 400px;
    margin: 10px;
  }

  @media (min-width: 900px) {
    max-width: none;
    margin: 10px;
  }
}

.images .temple {
  display: none;

  @media (min-width: 700px) {
    display: block;
    max-width: 400px;
    margin: 10px;
  }

  @media (min-width: 900px) {
    max-width: none;
    margin: 10px;
  }
}

.family,
.nephites,
.temple {
  display: none;

  @media (min-width: 920px) {
    display: block;
  }
}

.helpfulLinks {
  margin: 30px 10px;

  .link {
    display: flex;
    align-items: center;
    width: fit-content;
  }
}

.spiritualThought {
  margin: 30px 10px;
}

.thought {
  font-size: 1.1rem;

  .reference {
    display: block;
    margin-top: 20px;
    text-decoration: none;
    color: #3874cb;
    width: fit-content;
  }
}
