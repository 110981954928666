.container {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.header {
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.logo {
  width: 90px;
  margin-bottom: 10px;
}

.name {
  text-align: center;
}

.link {
  text-decoration: none;
  color: #4d4d4d;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  &:hover {
    color: dodgerblue;
  }
}

.active {
  background-color: #eeeeee;
}