.card {
  margin: 20px 0;
  padding: 0 20px;
  min-height: calc(100vh - 100px);

  @media (min-width: 800px) {
    width: calc(100vw - 300px);
  }
}

.announcement {
  border: solid 1px #d2d2d2;
  border-radius: 6px;
  margin: 20px 0;
  padding: 5px 10px;
  box-shadow: 5px 5px 5px #efefef;

  .subject {
    font-size: 1.1em;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .description {
    white-space: pre-line;
  }
}
