.card {
  margin: 20px 0;
  padding: 0 20px;
  min-height: calc(100vh - 100px);
  
  @media(min-width: 800px) {
    width: calc(100vw - 300px);
  }
}

.field {
  margin: 30px 0;
}