.container {
  margin: 10px 0;
}

.label {
  font-size: 18px;
  color: #8d8d8d
}

.link {
  display: flex;
  align-items: center;
  color: #4d4d4d;
  text-decoration: none;

  &:hover {
    color: dodgerblue;
  }
}

.noLabel {
  font-size: 18px;
}

.errorIcon {
  font-size: 20px;
  margin-left: 3px;
}
